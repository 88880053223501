.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* ********************  project css ********************************************** */


.header {
  position: relative; }

  .bg-gradient-info {
    background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important; }
    
    .logoDiv
{
background:white;
height:60px;
width:75px;
max-height:auto;
max-width:100%;
border-radius:100%;
}


.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

  .navbar-collapse-header {
    display: none; }

    .d-md-none {
      display: none !important; }

      .ml-auto,
.mx-auto {
  margin-left: auto !important; }




  
.menucolor{
  background-color:#0665A4 !important;
}


.mainlink{
  text-decoration: none;
  color:white;
  background-color:none ;
}

 .mainlink:hover{

  text-decoration: none;
  color:#0665A4 ;
  background-color:none ;
} 

.link{
  text-decoration: none;
  color:white;
  background-color:none ;
}

 .link:hover{

  text-decoration: none;
  color:white ;
  background-color:none ;
} 
.navbar-horizontal .navbar-nav .nav-link .nav-link-inner--text {
  margin-left: .10rem;
 }

 .footer{
  color:white;
}
.footer:hover{
  color:gray;
}
.footerCard{
  background-color:#352F2F ;
 }
 .insta{
  color:white;
  font-size: 25px;
  border-radius: 5px;
  text-decoration: none;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

.fb{

  color:white;
  border-radius: 5px;
  text-decoration: none;
  font-size: 25px;
}
.tw{
  background: white;
  color:#1DA1F2;
  border-radius: 5px;
  text-decoration: none;
  font-size: 25px;

}
.linkdin{
  background: white;
  color:#1DA1F2;
  border-radius: 2px;
  text-decoration: none;
  font-size: 25px;
}
.imgctr{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slider{
  max-width: 100%;
  max-height: auto;
  height:340px;
  /* border-color: #1171ef;
  border-style: solid;
  border-width: 2px;
 border-radius:2%; */
  width:1400px;
}

.news{
  height: 280px;
  /* border-color: #1171ef;
  border-style: solid;
  border-width: 2px; */

 


}

.news1{
  height: auto;
   border-color: #1171ef;
  border-style: solid;
  border-width: 1px; 
  border-radius:2%;
 


}

.slider1{
  border-color: #1171ef;
  border-style: solid;
  border-width: 1px;
 border-radius:2%;
 width: auto;
 
}

.pfont{
  font-size:20px;

}
.pr{
  font-size: 25px;
  color:#ED2A7B;
}
.ne{ 
  font-size: 22px;
  color:#2E3092;
}
.workflow{
  max-width: 100%;
  max-height: auto;
}
.responsive{
  max-width: 100%;
  max-height: auto;
  border-radius:180px;
  width:300px;
  height:250px;
 }
.tri{

  
    max-width: 100%;
    max-height: auto;
    border-radius:180px;
    width:500px;
    height:350px;
    margin-top: 80px;
   }

.old{
  max-width: 100%;
  max-height: auto;
  height:400px;
  border-radius: 5px;
  width:700px;
}
 .responsiveImg {
   
  max-width: 100%;
 max-height: auto;
 height:250px;
 border-radius: 5px;
 width:500px;
}

.wasteimg{
  background-image:url(https://i.postimg.cc/Df6Y1ghb/background-image.jpg);
  background-repeat: no-repeat;
  background-size: cover;
 
}  


.dshbrdimg{
  background-image:url(https://i.postimg.cc/FHZDRZND/dashbrd.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}  
/* 
.divbg{
  background-image:linear-gradient(to right bottom, #dd5e89  , #f7bb97) !important;
}  */

 .divbg{
  background-image:linear-gradient(to right bottom,#EE3884 ,  #2E3092) !important;
}  

.donorbg{
  background-image:linear-gradient(to right bottom ,#004e92  0,#000A5B) !important;
} 


.bg{
  background: url(https://i.postimg.cc/fRSnDStF/bge.png) ;
}

.bgfund{
  background: url(https://i.postimg.cc/bY9GFr8j/bg.png) ;
}

.hcolor{
  color:#1472B1;
}

.input-group-alternative {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
  transition: box-shadow .15s ease; }
  .input-group-alternative .form-control,
  .input-group-alternative .input-group-text {
    border: 0;
    box-shadow: none; }
    .focused .input-group-alternative {
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important; }


      .environment{
        max-width: 100%;
        max-height: auto;
        height:200px;
       
        width:1500px;
      }

      .rrr{
        max-height:auto;
        max-width: 100%;
      }


      .gallery{
        max-width: 100%;
        max-height: auto;
        height:300px;
        border-radius: 5px;
        width:400px; 
        
        
      }
      .gallery:hover{
        transform: scale(1.2);
      }
      
     
      
#students {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 100%;
}

#students td, #students th {
  border: 1px solid #ddd;
  padding: 8px;
}

#students tr:nth-child(even){background-color: #f2f2f2;}

#students tr:hover {background-color: #ddd;}

#students th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #11B2EF;
  color: white;
}
.nav-link{
  color:white !important;
  font-size:18px;
  margin-left: 0.4rem;
 }




 
.zoom{
  max-width: 100%;
  max-height: auto;
  height:350px;
  border-radius: 5px;
  width:500px;
}
.zoom:hover{
  transform: scale(1.2);
}





 
.Background {
	/* background: #1488CC;   
background: -webkit-linear-gradient(to top, #2B32B2, #1488CC);  
background: linear-gradient(to top, #2B32B2, #1488CC);  */
background: linear-gradient(90deg, #39A29E, #3B9DC2, #2C6EA4, #BC7DD7);
background-size: 400% 400%;
-webkit-animation: Gradient 15s ease infinite;
-moz-animation: Gradient 15s ease infinite;
animation: Gradient 10s ease infinite;
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}